import { Component } from "react";
import Work from "./Work";
import { Typography } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import "./style.css";

class Portfolio extends Component {
  constructor() {
    super();
    this.works = [
      {
        img: "https://res.cloudinary.com/dc7bhfpuo/image/upload/v1739903264/Thermal_Imager_Passive_Night_Vision_Binocular_PVS-7_with_5x_Lens_image__page-0001_yyemqn.jpg",
        title: "Thermal Imager Passive Night Vision Binocular PVS-7 with 5x Lens",
        text:
          "Thermal Imager Passive Night Vision Binocular PVS-7 with 5x Lens."
      }
    ];
  }

  handleDownload = (fileName) => {
    const link = document.createElement("a");
    link.href = `/files/${fileName}`; // Ensure PDFs are stored in public/files
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  render() {
    return (
      <section className="container container__portfolio" id="portfolio">
        <div className="portfolio__header" style={{ textAlign: "center", marginBottom: "20px" }}>
          <Typography variant="h2" component="h1" gutterBottom style={{ fontWeight: "bold" }}>
            Our Product
          </Typography>
          <div style={{ display: "flex", justifyContent: "center", gap: "24px", marginTop: "10px" }}>
            {/* Data Sheet Download */}
            <div 
              onClick={() => this.handleDownload("speci.pdf")} 
              style={{ 
                display: "flex", 
                alignItems: "center", 
                cursor: "pointer", 
                padding: "10px 20px", 
                borderRadius: "8px", 
                background: "#1976d2", 
                color: "white", 
                fontWeight: "bold",
                transition: "0.3s",
                boxShadow: "0px 4px 10px rgba(0,0,0,0.2)"
              }}
              onMouseEnter={(e) => e.currentTarget.style.background = "#1565c0"}
              onMouseLeave={(e) => e.currentTarget.style.background = "#1976d2"}
            >
              <DownloadIcon style={{ marginRight: "8px" }} />
              <Typography variant="body1">Data Sheet</Typography>
            </div>

            {/* Description Download */}
            <div 
              onClick={() => this.handleDownload("desc.pdf")} 
              style={{ 
                display: "flex", 
                alignItems: "center", 
                cursor: "pointer", 
                padding: "10px 20px", 
                borderRadius: "8px", 
                background: "#d32f2f", 
                color: "white", 
                fontWeight: "bold",
                transition: "0.3s",
                boxShadow: "0px 4px 10px rgba(0,0,0,0.2)"
              }}
              onMouseEnter={(e) => e.currentTarget.style.background = "#b71c1c"}
              onMouseLeave={(e) => e.currentTarget.style.background = "#d32f2f"}
            >
              <DownloadIcon style={{ marginRight: "8px" }} />
              <Typography variant="body1">Description</Typography>
            </div>
          </div>
        </div>

        {this.works.map((work, index) => (
          <Work key={index} img={work.img} title={work.title} text={work.text} />
        ))}
      </section>
    );
  }
}

export default Portfolio;
